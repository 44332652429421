import imageHero from "../assets/images/hero_banner.webp";
import { Container, FlexBox } from "./studium_ui";
import { createUseStyles, useTheme } from "react-jss";
import * as styles from "../utils/styles";
import Button from "./button/button";
import { landingItns } from "./landing.itn";
import { useItn } from "../utils/itn";
import { ST } from "../utils/themes";

export default function Hero() {
    const { itn } = useItn(landingItns);
    const theme = useTheme();
    // @ts-ignore
    const classes = useStyles({ theme });
    return (
        <section className={classes.container}>
            <Container
                className={classes.heroContainer}
                style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "stretch",
                }}
            >
                <FlexBox
                    style={{
                        height: "100%",
                        flex: 1,
                    }}
                    column
                    crossAxisAlignment="center"
                    gap={styles.size(1)}
                >
                    <FlexBox
                        column
                        flex={1}
                        mainAxisAlignment="center"
                        crossAxisAlignment="center"
                        gap={styles.size(1.5)}
                        style={{
                            maxWidth: "580px",
                            paddingBlock: styles.size(5),
                        }}
                    >
                        <p className={classes.supTitle}>{itn.hero.supTitle}</p>
                        <h1 className={classes.title}>
                            {itn.hero.title.map((line, index) => {
                                return (
                                    <span
                                        key={index}
                                        className={
                                            index % 2 === 0
                                                ? ""
                                                : classes.titleVariant
                                        }
                                    >
                                        {line}
                                        <br />
                                    </span>
                                );
                            })}
                        </h1>
                        <p className={classes.subtitle}>{itn.hero.subtitle}</p>
                        <a href="mailto:team@studium.academy?subject=Details">
                            <Button
                                style={{
                                    height: styles.size(4),
                                    fontSize: styles.size(1.5),
                                    paddingInline: styles.size(3),
                                }}
                            >
                                {itn.hero.callToAction}
                            </Button>
                        </a>
                    </FlexBox>
                    <FlexBox
                        crossAxisAlignment="center"
                        mainAxisAlignment="center"
                        flex={1}
                    >
                        <img
                            style={{
                                width: "70%",
                                marginBottom: "-40%",
                            }}
                            src={imageHero}
                            alt="hero"
                        />
                    </FlexBox>
                </FlexBox>
            </Container>
        </section>
    );
}

// region styles
const useStyles = createUseStyles({
    container: {
        color: ({ theme }: ST) => theme.foreground,
        paddingTop: styles.size(8),
        paddingBottom: styles.size(8),
        minHeight: "780px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "transparent",
        marginBottom: "40%",
        backgroundImage: ({ theme }: ST) =>
            `radial-gradient(at bottom center, ${theme.elementBlue}33, ${theme.background} 69%)`,
    },
    heroContainer: {},
    supTitle: {
        margin: 0,
        fontSize: styles.size(1.25),
        fontWeight: 500,
        color: ({ theme }: ST) => theme.elementBlue,
    },
    title: {
        // fontSize: styles.size(3.5),
        // lineHeight: 1.2,
        fontSize: styles.size(4.5),
        lineHeight: 1,
        margin: 0,
        fontWeight: 700,
        textAlign: "center",
    },
    titleVariant: {
        color: ({ theme }: ST) => theme.elementBlue,
    },
    subtitle: {
        fontSize: styles.size(1.125),
        opacity: 0.7,
        margin: 0,
        lineHeight: 1.5,
        marginBottom: styles.size(2),
        textAlign: "center",
    },
    image: {
        cursor: "pointer",
        transition: "all .2s ease-in-out",
        width: styles.size(16),
        // color: ({theme}: ST) => theme.elementBlue,
    },
});
// endregion
